import validate from "/tmp/build_066ed781/node_modules/nuxt/dist/pages/runtime/validate.js";
import company_45global from "/tmp/build_066ed781/middleware/company.global.ts";
import redirections_45global from "/tmp/build_066ed781/middleware/redirections.global.ts";
import manifest_45route_45rule from "/tmp/build_066ed781/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  company_45global,
  redirections_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/tmp/build_066ed781/middleware/authenticated.ts"),
  guest: () => import("/tmp/build_066ed781/middleware/guest.ts")
}