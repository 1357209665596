import { default as changel1JxDgbzbYMeta } from "/tmp/build_066ed781/pages/account/change.vue?macro=true";
import { default as indexdYxkigMWpDMeta } from "/tmp/build_066ed781/pages/account/index.vue?macro=true";
import { default as loginPjqH6x3fhrMeta } from "/tmp/build_066ed781/pages/account/login.vue?macro=true";
import { default as _91token_93ej8juDdqi2Meta } from "/tmp/build_066ed781/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as complete59DcNzspwjMeta } from "/tmp/build_066ed781/pages/account/password-reset/complete.vue?macro=true";
import { default as done3twQAXq7AzMeta } from "/tmp/build_066ed781/pages/account/password-reset/done.vue?macro=true";
import { default as indextprtWEIESrMeta } from "/tmp/build_066ed781/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetx2N6zF80VNMeta } from "/tmp/build_066ed781/pages/account/password-reset.vue?macro=true";
import { default as passwordG9e8Qb55ueMeta } from "/tmp/build_066ed781/pages/account/password.vue?macro=true";
import { default as registerJQTHGkA12PMeta } from "/tmp/build_066ed781/pages/account/register.vue?macro=true";
import { default as contactLauinpst3fMeta } from "/tmp/build_066ed781/pages/contact.vue?macro=true";
import { default as cookie_45policyldM4DawpZnMeta } from "/tmp/build_066ed781/pages/docs/cookie-policy.vue?macro=true";
import { default as privacy_45policyPvAu4CKnXkMeta } from "/tmp/build_066ed781/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45useyEh4907IM6Meta } from "/tmp/build_066ed781/pages/docs/terms-of-use.vue?macro=true";
import { default as _91slug_93RAjJZNew0uMeta } from "/tmp/build_066ed781/pages/events/[slug].vue?macro=true";
import { default as indexEUfEaJm8kNMeta } from "/tmp/build_066ed781/pages/events/index.vue?macro=true";
import { default as indexuuSdsak9rAMeta } from "/tmp/build_066ed781/pages/index.vue?macro=true";
import { default as _91id_93CHwHjy60ZeMeta } from "/tmp/build_066ed781/pages/orders/[id].vue?macro=true";
import { default as indexmlUSntEcmcMeta } from "/tmp/build_066ed781/pages/orders/index.vue?macro=true";
import { default as indexNv8ssAZWwaMeta } from "/tmp/build_066ed781/pages/purchase/index.vue?macro=true";
export default [
  {
    name: "account-change",
    path: "/account/change",
    meta: changel1JxDgbzbYMeta || {},
    component: () => import("/tmp/build_066ed781/pages/account/change.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexdYxkigMWpDMeta || {},
    component: () => import("/tmp/build_066ed781/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginPjqH6x3fhrMeta || {},
    component: () => import("/tmp/build_066ed781/pages/account/login.vue")
  },
  {
    name: password_45resetx2N6zF80VNMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetx2N6zF80VNMeta || {},
    component: () => import("/tmp/build_066ed781/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/tmp/build_066ed781/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/tmp/build_066ed781/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/tmp/build_066ed781/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/tmp/build_066ed781/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passwordG9e8Qb55ueMeta || {},
    component: () => import("/tmp/build_066ed781/pages/account/password.vue")
  },
  {
    name: "account-register",
    path: "/account/register",
    meta: registerJQTHGkA12PMeta || {},
    component: () => import("/tmp/build_066ed781/pages/account/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_066ed781/pages/contact.vue")
  },
  {
    name: "docs-cookie-policy",
    path: "/docs/cookie-policy",
    component: () => import("/tmp/build_066ed781/pages/docs/cookie-policy.vue")
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/tmp/build_066ed781/pages/docs/privacy-policy.vue")
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/tmp/build_066ed781/pages/docs/terms-of-use.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/tmp/build_066ed781/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/tmp/build_066ed781/pages/events/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_066ed781/pages/index.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93CHwHjy60ZeMeta || {},
    component: () => import("/tmp/build_066ed781/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexmlUSntEcmcMeta || {},
    component: () => import("/tmp/build_066ed781/pages/orders/index.vue")
  },
  {
    name: "purchase",
    path: "/purchase",
    component: () => import("/tmp/build_066ed781/pages/purchase/index.vue")
  }
]